import { provideSvgIconAppComputedStylesService } from '@bcflit-ds-components/base/svg-icon/svg-icon-app-computed-styles.service';
import { TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import { ActivatedRoute, provideActivatedRoute } from '@tmf-shared-platform/activated-route/activated-route';
import { HistoryUrl, provideHistoryUrl } from '@tmf-shared-platform/history-url/history-url';
import { BcfLitRouter } from '@tmf-shared-platform/router/internal';
import {
  RouterNavigatorService,
  provideRouterNavigatorService
} from '@tmf-shared-platform/router/router-navigator.service';
import { routerPageScrollerService } from '@tmf-shared-platform/router/router-page-scroller.service';

export class BcflitBaseAppWithInstrumentations extends TmfLitElement {
  public router: BcfLitRouter | undefined;

  constructor(
    private _activatedRoute: ActivatedRoute = provideActivatedRoute(),
    private _historyUrl: HistoryUrl = provideHistoryUrl()
  ) {
    super();
  }

  public override connectedCallback(): void {
    super.connectedCallback();
    const routerNavigatorService: RouterNavigatorService = provideRouterNavigatorService();
    routerNavigatorService.bindRouter(this.router!);
    routerNavigatorService.bindHistoryUrl(this._historyUrl);
    routerPageScrollerService.init();
    this._activatedRoute.addRouter(this.router!);
    provideSvgIconAppComputedStylesService().setComputedStyles(() => getComputedStyle(this));
    document.querySelector('.splash-screen')?.remove();
  }
}
