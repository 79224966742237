import { provideDiTokenValue } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { appStyles, appVariables } from '@bcflit-css/kingspin/desktop/app.styles';
import { ENV_COMMON } from '@bcflit-env/env-token';
import { ImportedElement } from '@tmf-shared-classes/tmf-lit-element';
import { BcfLitRouter } from '@tmf-shared-platform/router/internal';
import { CSSResult, TemplateResult, html } from 'lit';
import { property } from 'lit/decorators.js';
import { BcflitBaseAppWithInstrumentations } from '../../../bcflit-base-app-with-instrumentations';
import { appRoutes } from './app-routes';

export class AppElement extends BcflitBaseAppWithInstrumentations {
  public static override elementSelector: string = 'bcflit-app';
  public static override styles: CSSResult[] = [appVariables, appStyles];
  public override _importedElements: ImportedElement[] = [];

  @property({ attribute: false })
  public override router: BcfLitRouter = new BcfLitRouter(this, appRoutes(provideDiTokenValue(ENV_COMMON).langs));

  public override connectedCallback(): void {
    super.connectedCallback();
  }

  public override render(): TemplateResult {
    return html`${this.router.outlet()}`;
  }
}
