import { registerElement } from '@tmf-shared-classes/tmf-lit-element';
import { RouteConfig } from '@tmf-shared-platform/router/internal';
import { html } from 'lit';

export function appRoutes(langs: string[]): RouteConfig[] {
  return [
    {
      path: '/',
      render: () => html`<bcflit-ksm-default-lang-redirector-smart></bcflit-ksm-default-lang-redirector-smart>`,
      enter: async (): Promise<boolean> => {
        registerElement(
          (
            await import(
              '@bcflit-v1-ui-others-casino-components-kingspin-mobile/default-lang-redirector-smart/default-lang-redirector-smart.element'
            )
          ).DefaultLangRedirectorSmartElement
        );
        return true;
      }
    },
    ...langs.map(
      (lang: string) =>
        ({
          path: `/${lang}{/*}?`,
          render: () => html`<bcflit-ksd-lang-route .appLang="${lang}"></bcflit-ksd-lang-route>`,
          enter: async () => {
            registerElement((await import('./lang-route/lang-route.element')).LangRouteElement);
            return true;
          }
        }) as RouteConfig
    )
  ];
}
